.balancing {
  display: flex;
  padding: 110px 15px 50px 15px;
  align-items: center;
  gap: 199px;
  @media (max-width:1366px) {
    gap: 50px;
  }
  @media (max-width:991px) {
    flex-flow: wrap;
    gap:20px;
    padding:40px 15px 15px 15px;
  }
  &_left {
    img{
        width: 100%;
        @media (max-width:575px) {
          max-width: 350px;  
        }
    }
  }
  &_right {
    h2 {
     background:var(--headinggradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      line-height: 60px;
      font-size: var(--heading60);
      max-width: 578px;
      padding: 24px 0 30px;
      @media (max-width: 1199px) {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        line-height: 44px;
        padding-bottom: 10px;
      }
    }
    &_text {
      max-width: 682px;
      width: 100%;
      color:var(--textd1d1d1);
      font-size: var(--largeParaGraph);
      font-weight: 300;
    }
  }
}
