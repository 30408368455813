.contactUsMain {
  .contactUs {
    padding: 138px 15px 100px 15px;
    background-color: #121212;
    @media (max-width: 991px) {
      padding: 88px 15px 40px 15px;
    }
    h2 {
      font-size: var(--heading60);
      background: var(--headinggradientTwo);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: 24px;
      text-align: center;
      font-weight: 600;
      @media (max-width: 575px) {
        padding-bottom: 10px;
      }
    }
    p {
      text-align: center;
      color: var(--textd1d1d1);
      max-width: 979px;
      margin: auto;
    }
    &_form {
      max-width: 1265px;
      padding-top: 50px;
      width: 100%;
      margin: auto;
      @media (max-width: 767px) {
        padding-top: 20px;
      }
      &_btn {
        display: flex;
        justify-content: center;
        padding-top: 50px;
        .ant-btn{
          cursor: pointer;
          z-index: 9;
        }
      }
    }
  }
}
.contactUsBg {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-image: url("../../assets/contactusbg.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    width: 1028px;
    height: 100%;
    bottom: 0;
    right: 0;
    @media (max-width: 991px) {
      display: none;
    }
  }
}
