.customSelect {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 10px;
    color: var(--textd1d1d1);
    display: flex;
    font-weight: 300;
    align-items: first baseline;
    gap: 5px;
    font-size: var(--textfourteen);
    sup {
      color: var(--dropborder);
    }
    span {
      color: red;
    }
  }
  .ant-select {
    height: 59px;
    box-shadow: none;
    border: 0 !important;
    outline: none;
    border-color: transparent;
    z-index: 2;
    @media (max-width: 991px) {
      height: 45px;
    }
    .ant-select-selection-placeholder {
      color: #393939;
    }
    .ant-select-selector {
      background: var(--innercardbg);
      font-size: var(--textfourteen);
      color: var(--white);
      font-weight: 300;
      border: 0;
      box-shadow: unset !important;
      border: 0 !important;
      border-color: transparent;
      outline: none;
      border-radius: 5px;
      padding: 0 25px 0 25px !important;
      @media (max-width:991px) {
        padding:0 15px 0 15px !important;
      }
      .ant-select-selection-search {
        input {
          padding-left: 13px;
          @media (max-width:991px) {
          padding-left: 4px;
          }
        }
      }
    }
    .ant-select-arrow {
      span {
        svg {
          fill: var(--gray656565);
          font-size: 19px;
          font-weight: 300;
          @media (max-width:575px) {
            font-size: 14px;
            
          }
        }
      }
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #201e1e !important;
}
.ant-select-selection-item{
  color:var(--white) !important;
}
.ant-select-item{
  padding-left:20px !important;
}