@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // Color vars//
  --darkBlue: #00bef2;
  --darkPurple: #0000b2;
  --HoverAnchor: #d4d4d4;
  --roadmaphead: #00ffff;
  --textd1d1: #d1d1d1;
  --bodybg: #121212;
  --bg252525: #252525;
  --innercardbg: #1e1e1e;
  --text595959: #595959;
  --downloadbg: #c2c6cc;
  --textd1d1d1: #d1d1d1;
  --bgbenefitImg: #d9d9d9;
  --FooterBg: #0d1b26;
  --brandPurple10: #5700ff10;
  --dropborder: #cffe25;
  --coreGray: #9ba0a6;
  --offWhite: #f9f9fd;
  --black: #000000;
  --usecaseborder:rgba(255, 255, 255, 0.1);
  --mutedBlack: rgba(0, 0, 0, 0.5);
  --white: #ffffff;
  --validation: #ffb82d;
  --error: #f93232;
  --success: #00b25d;
  --disabledButton: #5500ff10;
  --blue: #4285f4;
  --lightPink: #f8f8fc;
  --black60: #00000099;
  --black40: #00000040;
  --grayBorder: #ffffff1a;
  --grayE9E9EF: #e9e9ef;
  --grayDark636364: #636364;
  --borderColor: rgba(175, 168, 168, 0.4);
  --gray666666: #666666;
  --gray656565: #656565;
  --commonPtext: #9ba0a6;
  --headingColor: #ececec;
  --cardbg: linear-gradient(
    289.13deg,
    rgba(222, 243, 255, 0.2) 12.47%,
    rgba(255, 255, 255, 0) 124.3%
  );
  --cardborder: linear-gradient(
    257.6deg,
    rgba(255, 255, 255, 0.3) 35.29%,
    rgba(255, 255, 255, 0.06) 72.31%
  );
  --headinggradient: linear-gradient(271.73deg, #393939 5.4%, #eeeeee 90.42%);
  --headinggradientTwo: linear-gradient(
    92.71deg,
    #ffffff 2.26%,
    #404040 81.47%
  );
  // Typography //
  --heading100: 100px;
  --heading80: 80px;
  --heading70: 70px;
  --heading50: 50px;
  --heading64: 64px;
  --heading60: 60px;
  --heading56: 56px;
  --heading48: 48px;
  --heading44: 44px;
  --heading40: 40px;
  --headingOne: 32px;
  --heading30: 30px;
  --heading26: 26px;
  --headingTwo: 22px;
  --headingThree: 24px;
  --headingFour: 20px;
  --largeParaGraph: 18px;
  --largeParaGraph16: 16px;
  --regularParagraph: 15px;
  --mediumParaGraph: 16px;
  --textfourteen: 14px;
  --smallParagraph: 13px;
}

/* Responsive styles */
@media screen and (max-width: 1440px) {
  :root {
    --heading64: 52px;
    --heading56: 46px;
    --heading48: 34px;
    --heading70: 50px;
    --heading80: 52px;
    --heading60: 48px;
    --heading44: 34px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --heading64: 43px;
    --heading56: 40px;
    --heading100: 70px;
    --heading64: 30px;
    --heading70: 30px;
    --heading80: 46px;
    --heading48: 28px;
    --heading50: 30px;
    --heading80: 44px;
    --heading44: 28px;
    --heading60: 42px;
    --heading40: 32px;
    --headingOne: 28px;
    --heading30: 26px;
    --heading26: 18px;
    --headingTwo: 20px;
    --headingThree: 22px;
    --headingFour: 18px;
    --largeParaGraph: 16px;
    --largeParaGraph16: 15px;
    --regularParagraph: 14px;
    --textfourteen: 12px;
    --mediumParaGraph: 13px;
    --smallParagraph: 12px;
  }
}

@media screen and (max-width: 575px) {
  :root {
    --heading64: 32px;
    --heading56: 28px;
    --heading100: 40px;
    --heading48: 28px;
    --heading44: 22px;
    --heading80: 44px;
    --heading40: 20px;
    --heading50: 30px;
    --headingOne: 24px;
    --heading60: 30px;
    --heading30: 20px;
    --headingTwo: 18px;
    --heading26: 16px;
    --headingThree: 20px;
    --headingFour: 16px;
    --largeParaGraph: 14px;
    --largeParaGraph16: 14px;
    --regularParagraph: 13px;
    --mediumParaGraph: 12px;
    --smallParagraph: 11px;
  }
}

h1,p
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  font-family: "Mulish", sans-serif;
  margin: 0;
  padding: 0;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--bodybg);
}
* {
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
}
#root {
  width: 100%;
}

// img {
//   width: 100%;
// }

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

h2.ant-typography {
  font-size: var(--heading70);
  margin: unset;
  line-height: unset;
  text-align: center;
}

h3.ant-typography {
  font-size: var(--heading40);
  margin: unset;
  line-height: unset;
  color: var(--darkBlue);
}

.container {
  padding: 0 15px;
  max-width: 1597px;
  margin: auto;
}

.bannerBlueText {
  color: var(--HoverAnchor);
  white-space: nowrap;

  @media (max-width: 480px) {
    white-space: wrap;
  }
}

.form-outer {
  // max-width: 690px;
  background-color: rgba(6, 18, 32, 1);
  border-radius: 15px;
  padding: 60px 40px;
  border: 1px solid rgba(38, 41, 60, 0.7);
  margin: auto;

  @media (max-width: 991px) {
    padding: 40px 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
  }

  textarea {
    background-color: #131e2b;
    border: 1px solid rgba(38, 41, 60, 0.7);
    color: white;
    z-index: 2;
    &.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
      background-color: #131e2b;
    }

    &.ant-input-outlined {
      &:focus,
      &:hover,
      &:focus-within {
        background-color: #131e2b;
        border-color: var(--darkBlue);
      }
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .ant-form-item {
    .ant-form-item-label > label {
      color: rgba(255, 255, 255, 0.5);
    }

    .ant-form-item-explain-error {
      text-align: initial;
      font-size: 12px;
    }
  }

  button {
    margin: auto;
  }
}
.customInput {
  input.ant-input {
    background-color: #131e2b;
    padding: 15px 18px;
    border: 1px solid rgba(38, 41, 60, 0.7);
    color: white;
    z-index: 2;
    @media (max-width: 991px) {
      padding: 12px 15px;
    }

    @media (max-width: 767px) {
      padding: 10px 12px;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &:focus,
    &:hover,
    &:focus-within {
      background-color: #131e2b;
      border-color: var(--darkBlue);
    }
  }

  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background-color: #131e2b;
  }

  .ant-input-outlined {
    border: unset;
  }
}
.ant-select-dropdown {
  background-color: var(--innercardbg) !important;
  .ant-select-item-option {
    &:hover {
      background-color: var(--bodybg) !important;
    }
  }
  .ant-select-item-option-content {
    color: var(--white);
  }
}
.ant-dropdown-trigger {
  cursor: pointer;
  border: 1px solid var(--dropborder);
  color: var(--dropborder);
  padding: 10px 45px;
  border-radius: 26px;
  max-width: 174px;
  width: 100%;
  display: flex;
  font-weight: 500;
  justify-content: center;
  gap: 12px;
  @media (max-width: 1366px) {
    width: 139px;
  }
}
.ant-dropdown-menu {
  background-color: var(--innercardbg) !important;
  .ant-dropdown-menu-item {
    color: var(--dropborder) !important;
    &:hover {
      background-color: var(--bodybg) !important;
    }
  }
}
.greentext {
  color: var(--dropborder);
  font-size: var(--headingFour);
  font-weight: 400;
}
//playappstorebutton
.bannerbuttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  @media (max-width: 575px) {
    gap: 10px;
  }
  a {
    max-height: 60px;
    @media (max-width: 575px) {
      max-height: unset;
    }
    svg {
      height: 100%;
      max-width: 200px;
      width: 100%;
      @media (max-width: 575px) {
        max-width: unset;
      }
      &:hover {
        -webkit-box-shadow: 0px 0px 19px 0px rgba(174, 199, 72, 1);
        -moz-box-shadow: 0px 0px 19px 0px rgba(174, 199, 72, 1);
        box-shadow: 0px 0px 19px 0px rgba(174, 199, 72, 1);
        border-radius: 30px;
        @media (max-width: 575px) {
          -webkit-box-shadow: unset;
          -moz-box-shadow: unset;
          box-shadow: unset;
        }
      }
    }
  }
}
// .commonHead {
//   font-weight: 600;
//   font-size: var(--heading26);
// }
// .commonText {
//   font-size: var(--largeParaGraph);
//   font-weight: 300;
//   color: var(--textd1d1);
// }
