.simplifiedTransaction {
  padding: 110px 15px;
  @media (max-width:767px) {
    padding:40px 15px;
    
  }
  h2 {
   background:var(--headinggradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    line-height: 60px;
    font-size: var(--heading60);
    max-width: 807px;
    padding-bottom: 30px;
    @media (max-width: 1199px) {
      max-width: 100%;
    }
    @media (max-width:767px) {
      line-height: 44px;
      padding-bottom: 10px;
    }
  }
  .transactions {
    display: flex;
    gap: 100px;
    align-items: flex-start;
    @media (max-width: 1366px) {
      gap: 30px;
    }
    @media (max-width: 1199px) {
      flex-flow: wrap;
    }
    &_left {
      img {
        max-width: 550px;
        @media (max-width:991px) {
          width: 100%;
          
        }
      }
    }

    &_right {
      display: flex;
      gap: 20px;
      margin-top: 30px;
      margin-bottom: 51px;
      @media (max-width: 1366px) {
        gap: 0;
      }
      @media (max-width:575px) {
        flex-direction: column;
        margin-top: 0;
        
      }
      &_cards {
        background-color: var(--innercardbg);
        padding: 20px;
        flex: 1;
        &:nth-child(2) {
          background-color: unset;
        }
        h4 {
          color: var(--white);
          font-size: var(--headingFour);
          font-weight: 600;
          padding-bottom: 8px;
        }
        p {
          font-size: var(--mediumParaGraph);
          font-weight: 300;
          line-height: 30px;
          @media (max-width:575px) {
            line-height: 24px;            
          }
        }
        span {
          color: var(--white);
          opacity: 0.1;
          font-size: var(--heading30);
          padding-bottom: 20px;
          display: block;
          font-weight: 700;
          @media (max-width:575px) {
            padding-bottom: 12px;
            
          }
        }
      }
    }
    .bannerbuttons {
      justify-content: flex-start;
    }
  }
}
