.customInput {
    text-align: left;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 10px;
      color:var(--textd1d1d1);
      display: flex;
      font-weight: 300;
      align-items: first baseline;
      gap:5px;
      font-size: var(--textfourteen);
      sup{
        color:var(--dropborder);
      }
      span {
        color: red;
      }
    }
    input,Textarea {
      box-sizing: border-box;
      border: none;
      padding: 10px 25px;
      height: 59px;
      background: var(--innercardbg);
      font-size: var(--textfourteen);
      color: var(--white);
      font-weight: 300;
      border-radius: 5px;
      z-index: 2;
      @media (max-width: 991px) {
        height: 45px !important;
        padding:10px 15px;
      }
      &::placeholder {
        color:var(--text595959);
      }
      &:disabled {
        outline: unset;
        opacity: 50%;
      }
      &:is(:focus, :focus-visible, :focus-within) {
        border: 0;
      }
      &:focus-visible {
        outline: none;
      }
    }
    &-inputError {
      input {
        outline: 1px solid var(--error) !important;
  
        &:hover {
          outline: 1px solid var(--error) !important;
        }
      }
      label {
        padding-top: 8px;
      }
    }
  }
  Textarea {
   min-height: 161px;
  }
