.superApp {
  padding: 150px 15px 0px 15px;
  @media (max-width: 767px) {
    padding: 40px 0px;
  }

  h2 {
    background: linear-gradient(89.52deg, #ffffff 7.71%, #d0d0d0 91.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: var(--heading70);
    font-weight: 600;
    line-height: 80px;
    max-width: 808px;
    margin: auto;
    @media (max-width: 991px) {
      line-height: 60px;
      padding: 0 15px;
    }
    @media (max-width: 575px) {
      line-height: 40px;
      font-size: 30px;
    }
    span {
      background: linear-gradient(88.08deg, #393939 13.91%, #a0a0a0 89.61%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  p {
    color: var(--textd1d1);
    max-width: 844px;
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 70px;
    @media (max-width: 991px) {
      padding: 10px 15px;
    }
  }
  &_mobile {
    position: relative;
    video {
      width: 100%;
      height: auto;
      display: block;
      @media (max-width: 991px) {
        object-fit: none;
        height: 100vh;
      }
    }

    &_data {
      position: absolute;
      top: 28%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
      background: transparent;
      padding: 10px;
      border-radius: 8px;
      @media (max-width: 1366px) {
        top: 40%;
        border-radius: 68px;
        overflow: hidden;
      }
      @media (max-width: 575px) {
        padding: 0;
        border-radius: 25px;
      }
      .frame-container {
        position: relative;
        width: 100%;
        height: auto;
        z-index: 9;
        @media (max-width: 575px) {
          width: 234px;
          border-radius: 39px;
          overflow: hidden;
          background: var(--bodybg);
        }
      }
      .frame-image {
        height: auto;
        display: block;
        @media (max-width: 575px) {
          width: 100%;
          margin: auto;
        }
      }
      .carousel-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        z-index: -1;
        padding: 2px;
        @media (max-width: 575px) {
          padding: 0;
        }
      }
      .ant-carousel {
        @media (max-width: 575px) {
          max-width: 212px;
        }
        .slick-list {
          border-radius: 50px;
          @media (max-width: 575px) {
            border-radius: 29px;
          }
        }
      }
      .carousel-overlay .ant-carousel {
        width: 100%;
        height: 100%;
        pointer-events: auto;
      }
      .slick-dots {
        li,
        .slick-active {
          button {
            background: none !important;
          }
        }
      }
      .slick-slide {
        border-radius: 30px !important;
        overflow: hidden !important;
        img {
          width: 100%;
          max-height: 602px;
          max-width: 276px;
          margin: auto;
          padding: 10px 0px 0px 0px;
          border-radius: 30px;
          overflow: hidden;
          @media (max-width: 666px) {
            // max-height: 500px;
          }
        }
      }
      .slick-active {
        Button:after,
        :before {
          display: none;
        }
      }
      &_buttons {
        display: flex;
        justify-content: center;
        gap: 16px;
      }
    }
    h3 {
      font-size: var(--heading80);
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
      line-height: 86px;
      padding-bottom: 30px;
    }
  }
}
