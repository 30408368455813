.useCases {
  padding: 80px 100px 100px 100px;
  background-color: var(--innercardbg);
  @media (max-width: 1499px) {
    padding: 30px;
  }
  @media (max-width: 767px) {
    padding: 15px;
  }
  h2 {
    text-align: center;
    background: var(--headinggradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--heading60);
    line-height: 60px;
    max-width: 616px;
    font-weight: 600;
    margin: auto;
    @media (max-width: 767px) {
      text-align: left;
      margin: unset;
      line-height: 40px;
    }
  }
  p {
    text-align: center;
    padding-top: 20px;
    @media (max-width: 767px) {
      text-align: left;
      padding-top: 12px;
    }
  }
  &_inner {
    display: flex;
    flex-flow: wrap;
    padding-top: 40px;
    row-gap: 69px;
    @media (max-width: 1546px) {
      gap: 20px;
      justify-content: center;
    }
    @media (max-width: 767px) {
      row-gap: 20px;
      padding-top: 15px;
    }
    &_cards {
      max-width: 455px;
      width: 100%;
      position: relative;
      @media (max-width: 1546px) {
        max-width: 410px;
      }
      @media (max-width: 1366px) {
        max-width: 48%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }
      p {
        text-align: left;
        color: var(--white);
        max-width: 400px;
        width: 100%;
        font-size: var(--largeParaGraph16);
        padding-top: 8px;
        line-height: 30px;
        @media (max-width: 1366px) {
          max-width: 100%;
        }
        @media (max-width: 575px) {
          padding-top: 6px;
          line-height: 24.32px;
        }

        @media (max-width: 767px) {
          max-width: 100%;
        }
      }
      h4 {
        color: var(--textd1d1d1);
        padding-top: 30px;
        font-size: var(--headingFour);
        @media (max-width: 575px) {
          padding-top: 20px;
        }
      }
      &:after {
        content: "";
        position: absolute;
        right: 36px;
        height: 49px;
        border-right: 1px solid var(--usecaseborder);
        bottom: 40px;
        @media (max-width:1546px) {
          right: 8px;
        }
        @media (max-width:1366px) {
          border-right: 0;
        }
      }
      &:nth-child(3),&:nth-child(6){
        &::after{
          content: unset;
        }
      }
    }
  }
}
