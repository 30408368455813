.deepdive {
  background: linear-gradient(
    180deg,
    #d1d6d9 0%,
    #ced3d6 25%,
    #ccd0d3 50%,
    #c2c6cc 100%
  );
  position: relative;
  overflow: hidden;
  @media (max-width: 1630px) {
    height: 100vh;
    object-fit: unset;
  }
  @media (max-width: 991px) {
    background-color: #cfd3d7;
    height: auto;
  }
  &_video {
    width: 100%;
    height: auto;
    display: block;
    @media (max-width: 1630px) {
      height: 100vh;
      object-fit: unset;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  h2 {
    color: var(--bodybg);
    font-size: var(--heading60);
    line-height: 60px;
    font-weight: 600;
    max-width: 581px;
    padding: 0px 15px 50px 15px;
    @media (max-width: 991px) {
      padding: 40px 15px 16px;
    }
    @media (max-width: 575px) {
      line-height: 34px;
    }
  }
  .deepdiveinner {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    padding: 10px 15px;
    border-radius: 8px;
    width: 100%;
    @media (max-width: 991px) {
      transform: none;
      position: static;
    }

    &_feature {
      display: flex;
      gap: 60px;
      @media (max-width: 991px) {
        flex-flow: wrap;
        gap: 0;
      }
      &_listing {
        .listingItems {
          display: flex;
          gap: 27px;
          align-items: first baseline;
          @media (max-width: 575px) {
            gap: 13px;
          }
          span {
            color: rgba(18, 18, 18, 0.4);
            font-size: var(--headingFour);
            font-weight: 600;
          }
          div {
            max-width: 533px;
            width: 100%;
            border-top: 1px solid var(--borderColor);
            border-bottom: 1px solid var(--borderColor);
            padding: 16px 0 17px 0;
            h4 {
              color: var(--bodybg);
              font-weight: 500;
              font-size: var(--headingFour);
            }
            p {
              font-weight: 300;
              color: var(--bodybg);
              font-size: var(--largeParaGraph16);
            }
          }
        }
      }
    }
  }
}
