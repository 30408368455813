.btnCustom.ant-btn {
  width: 100%;
  padding: 0px 14px;
  font-size: var(--largeParaGraph16);
  font-weight: 500;
  text-align: center;
  transition: all 0.25s;
  border: 0px;
  box-shadow: none;
  border-radius: 10px;
  height: 46px;
  background-color: transparent;
  color: var(--dropborder);
  font-family: "Montserrat", sans-serif;
  &:hover {
    background-color: #c4f024 !important;
    color: #fff !important;
    border: 1px solid #c4f024 !important;
  }
  .leftIcon {
    margin-right: 7px;
    display: flex;
  }

  .rightIcon {
    margin-left: 5px;
    line-height: 10px;
  }
  &.greenborder {
    border: 1px solid var(--dropborder);
    color: var(--dropborder);
    padding: 10px 45px;
    border-radius: 26px;
    max-width: 174px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
    @media (max-width:1366px) {
      width: 139px;
    }
  }
  &.greenbutton {
    background-color: var(--dropborder);
    color: var(--black);
    border-radius: 60px;
    max-width: 200px;
    width: 100%;
    height: 60px;
    font-weight: 400;
    @media (max-width:991px) {
      height: 46px;
    }
    &:hover{
      box-shadow: 0px 0px 19px 0px rgba(174,199,72,1);
      color:var(--black) !important;
      @media (max-width:575px) {
        box-shadow: unset;
      }
    }
  }
}

.btnBorder {
  border: 1px solid var(--darkPurple) !important;
  background: var(--headerBg) !important;
  color: var(--darkPurple) !important;
}
